import React, { createContext, useContext, useState } from 'react';

const BookingContext = createContext();

export const BookingProvider = ({ children }) => {
    const [isBookingVisible, setIsBookingVisible] = useState(true);

    return (
        <BookingContext.Provider value={{ isBookingVisible, setIsBookingVisible }}>
            {children}
        </BookingContext.Provider>
    );
};

export const useBooking = () => {
    return useContext(BookingContext);
};
