import React from "react";
import { BsPlay, BsInstagram, BsFacebook, BsCamera } from 'react-icons/bs'
import {AiOutlineInstagram, AiOutlineCamera, AiOutlineYoutube, AiFillInstagram, AiFillFacebook} from 'react-icons/ai'
import { ImFilm } from 'react-icons/im'
import {FiLinkedin} from 'react-icons/fi'

const Icon = ({icon, color}) => {

    console.log(icon)

    switch(icon){
        case 'facebook':
            return <AiFillFacebook size={25} className={"fill-black dark:fill-white"}/>;
        case 'instagram':
            return <AiFillInstagram size={25} className={"fill-black dark:fill-white"}/>;
    }
    return (
        <></>
    )
}

export default Icon;
