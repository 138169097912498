import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import Navigation from "../components/layout/Navigation";
import PageTransition from "../components/layout/PageTransition";
import SEO from "../components/helper/SEO";
import {graphql, Link, StaticQuery, useStaticQuery} from "gatsby";
import MyPortableText from "../components/helper/MyPortableText";
import {BookingProvider} from "../components/layout/BookingContext";
import {LocomotiveScrollProvider} from "react-locomotive-scroll";

const Layout = ({children, location, data}) => {

    console.log(data);
    const staticSettings = data?.allSanitySettings?.edges[0]?.node;
    const [maintenanceMode, setMaintenanceMode] = useState(true);

    useEffect(() => {
        if (location?.hostname !== 'gattoimmuseum.at') {
            setMaintenanceMode(false);
        }
    }, [location]);

    const [isDark, setIsDark] = useState(false);

    useEffect(() => {
        const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

        const currentTime = new Date();
        const currentHour = currentTime.getHours();

        const shouldUseDarkMode = currentHour >= 16 || currentHour < 8 || prefersDarkMode;

        setIsDark(shouldUseDarkMode);
    }, []);

    console.log(staticSettings);
    if (staticSettings?.maintenance && maintenanceMode) {
        console.log('maintenance mode aktiv')
        return (<>
            <SEO title={staticSettings?.maintenance_seo_title} description={staticSettings?.maintenance_seo_description} location={location}></SEO>
            <div className={"maintenance top-0 left-0 h-screen w-screen bg-white flex justify-center items-center flex-col"} style={{
                height: '100vh',
                width: '100vw'
            }}>
                <div className="reveal mx-page">
                    <h1 className={`font-serif italic font-bold text-4xl medium:text-5xl tablet:text-7xl z-10 relative mb-5 center-aligned reveal`}>{staticSettings?.maintenancetitle}</h1>
                </div>
                <div className={"text-center reveal mx-page"}>
                    <MyPortableText value={staticSettings?._rawMaintenancetext}></MyPortableText>
                </div>
                <div className={"flex justify-center reveal mt-5 mx-page"}>
                    <a href={staticSettings?.maintenancebutton?.value} className={`nav-link h-7 block font-bold text-black dark:text-white border-2 border-black dark:border-white rounded-full px-3 transition-all ease-in-out duration-500 hover:bg-black hover:text-white`}>{staticSettings?.maintenancebutton?.title}</a>
                </div>
            </div>
        </>)
    } else {
        console.log('maintenance mode inaktiv')
        return (
                    <div className={`${isDark ? 'dark' : ''}`}>
                        <BookingProvider>
                            <Navigation location={location} settings={staticSettings} isDark={isDark} toggleDark={setIsDark}></Navigation>
                            <PageTransition location={location}>
                                {children}
                            </PageTransition>
                        </BookingProvider>
                    </div>
        )
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired, data: PropTypes.shape({
        allSanitySettings: PropTypes.shape({
            edges: PropTypes.shape({
                node: PropTypes.shape({
                    maintenance: PropTypes.string.isRequired,
                }),
            }).isRequired,
        }).isRequired,
    }).isRequired,
}


// 👇 Query component
export default function myLayout(props) {
    return (<StaticQuery
            query={graphql`
        query SiteTitleQuery {
          allSanitySettings{
                edges {
                    node {
                        maintenance
                        maintenance_seo_description
                        maintenance_seo_title
                        maintenancetitle
                        maintenancebutton {
                            value
                            title
                        }
                        _rawMaintenancetext
                        address_street
                        address_nr
                        address_plz
                        address_ort
                        sociallinks{
                            value
                            title
                            type
                        }
                        email
                    }
                }
            }
        }
      `}
            render={data => <Layout data={data} {...props} />}
        />);
}
